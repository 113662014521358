/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* colors*/
.colors {
    --green: var(#14b8a6);
    --black: var(#334155);
    --white: var(#e2e8f0);
}
